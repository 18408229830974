import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeMenu: 0,
    hamburger: false,
    activeTeam: 1,
  },
  getters: {
    activeMenu: state => {
      return state.activeMenu;
    },
    hamburger: state => {
      return state.hamburger;
    },
    activeTeam: state => {
      return state.activeTeam;
    },
  },
  mutations: {
    setActiveMenu: function (state, newValue){
      state.activeMenu = newValue;
    },
    toggleHamburger: function (state){
      state.hamburger = !state.hamburger;
    },
    setActiveTeam: function (state, newValue){
      state.activeTeam = newValue;
    }
  },
  actions: {
  },
  modules: {
  }
})
