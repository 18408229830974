<script>
import Hamburger from "@/components/Hamburger.vue";

export default {
    name: "NavbarMobile",
    components: {
        Hamburger
    },
    computed: {

    },
}
</script>

<template>
  <div class="navbarmobile">
    <img src="../assets/img/logo.png" />
      <Hamburger />
  </div>
</template>

<style scoped>
@media (min-width: 0px) and (max-width: 1023px){
    .navbarmobile{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 70px;
        background-color: #0E2044;
        padding-left: 20px;
        padding-top: 13px;
    }

    .navbarmobile img{
        width: 70%;
    }
}

@media (min-width: 1024px){
    .navbarmobile{
        display: none;
    }
}
</style>