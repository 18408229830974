<script>
import {gsap} from "gsap";

export default {
    computed: {
        activeTeam(){
            return this.$store.getters.activeTeam;
        }
    },
    data() {
        return {
            teamDetails: [
                {
                    name: 'Fehér Anikó',
                    title: 'Ügyvezető igazgató',
                    school: 'Okleveles építőmérnök',
                },
                {
                    name: 'Pál Domonkos',
                    title: 'Ügyvezető igazgató',
                    school: 'Okleveles településmérnök',
                    phone: '+36 30 3982 962',
                    email: 'pal.domonkos@fehervariviziterv.hu',
                },
                {
                    name: 'Józsa Benedek',
                    title: 'Infrastruktúra tervező',
                    school: 'Okleveles építőmérnök',
                    phone: '+36 20 230 0404',
                    email: 'jozsa.benedek@fehervariviziterv.hu',
                },
                {
                    name: 'Király Dániel',
                    title: 'Infrastruktúra tervező',
                    school: 'Okleveles építőmérnök',
                    phone: '+36 20 371 9700',
                    email: 'kiraly.daniel@fehervariviziterv.hu',
                },
                {
                    name: 'Szilágyi Viktor',
                    title: 'Közlekedésépítési tervező',
                    school: 'Építőmérnök',
                    phone: '+36 70 507 4951',
                    email: 'szilagyi.viktor@fehervariviziterv.hu',
                },
                {
                    name: 'Huncsik Dániel',
                    title: 'Víziközmű tervező',
                    school: 'Építőmérnök',
                    phone: '+36 70 507 4951',
                    email: 'huncsik.daniel@fehervariviziterv.hu',
                },
                {
                    name: 'Pénzár Ádám',
                    title: 'Víziközmű tervező',
                    school: 'Építőmérnök',
                    phone: '+36 70 507 4951',
                    email: 'penzar.adam@fehervariviziterv.hu',
                },
                {
                    name: 'Kállai Alexandra',
                    title: 'Grafikus, irodai asszisztens',
                    school: 'Képesített grafikus',
                    phone: '+36 70 507 4951',
                    email: 'nyomtatas@fehervariviziterv.hu',
                },
            ]
        }
    },
    methods: {
        closeMenu(){
            gsap.timeline().to(".team__details", {
                autoAlpha: 0,
                duration: 0.2,
            },);
        }
    }
}
</script>

<template>
  <div class="team__details">
    <b-row>
        <b-col>
          <img v-if="activeTeam === 1" src="../assets/img/team/01_aniko.jpg"/>
          <img v-else-if="activeTeam === 2" src="../assets/img/team/02_doma.jpg"/>
          <img v-else-if="activeTeam === 3" src="../assets/img/team/03_benedek.jpg"/>
          <img v-else-if="activeTeam === 4" src="../assets/img/team/04_kdani.jpg"/>
          <img v-else-if="activeTeam === 5" src="../assets/img/team/05_viktor.jpg"/>
          <img v-else-if="activeTeam === 6" src="../assets/img/team/06_hdani.jpg"/>
          <img v-else-if="activeTeam === 7" src="../assets/img/team/07_adam.jpg"/>
          <img v-else-if="activeTeam === 8" src="../assets/img/team/08_szandra.jpg"/>
        </b-col>
        <b-col class="position-relative">
            <h1>
                {{teamDetails[activeTeam-1].name}}
            </h1>
            <h2>{{teamDetails[activeTeam-1].title}} | {{teamDetails[activeTeam-1].school}}</h2>
            <br>
            <br>
            <h3>{{teamDetails[activeTeam-1].phone}}</h3>
            <h3>{{teamDetails[activeTeam-1].email}}</h3>

            <br>
            <br>
            <h4 @click="closeMenu">Vissza</h4>
        </b-col>
    </b-row>
  </div>
</template>

<style scoped>
@media (min-width: 0px) and (max-width: 1023px){
    .team__details{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: #0E2044;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        visibility: hidden;
        padding-top: 10vh;
    }

    .col{
        height: 100vh;
        padding: 5%
    }

    img{
    //width: 100%;
        width: 95vw;
        height: auto;
    //width: 50vw;
    }

    br{
        display: none;
    }
    h1{
        font-size: 20pt;
        color: #FEC400;
    }

    h2, h4{
        font-size: 16pt;
        color: #e8b10d;
    }

    h3{
        font-size: 14pt;
    }

    h4{
        margin-top: 40px;
        text-align: right;
        cursor: pointer;
        transition: ease all 0.2s;
    }

    h4:hover{
        color: #FEC400;
    }

    .col-6, .col-4, .row, img, iframe, .col, .col-1, .col-8, .col-7, .col-5, .col-3{
        width: 100%;
    }

    .col{
        flex: initial;
        height: fit-content;
    }

    .col-6, .col-4, .col, .col-1, .col-8, .col-7, .col-5, .col-3{
        padding: 20px;
    }

    .row{
        margin: 0;
    }
}

@media (min-width: 1024px){
    .team__details{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: #0E2044;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        visibility: hidden;
        padding-top: 10vh;
    }

    .col{
        height: 100vh;
        padding: 5%
    }

    img{
    //width: 100%;
        height: 80%;
    //width: 50vw;
    }

    h1{
        color: #FEC400;
    }

    h2, h4{
        color: #e8b10d;
    }

    h4{
        text-align: right;
        cursor: pointer;
        transition: ease all 0.2s;
    }

    h4:hover{
        color: #FEC400;
    }
}

</style>