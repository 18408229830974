<script>
import NavBar from "@/components/NavBar.vue";
import NavbarMobile from "@/components/NavbarMobile.vue";
import MenuMobile from "@/components/MenuMobile.vue";
import TeamDetails from "@/components/TeamDetails.vue";

export default {
    components: {
        TeamDetails,
        MenuMobile,
        NavBar,
        NavbarMobile
    }
}
</script>

<template>
    <div id="app">

        <router-view/>
        <div class="loading">

        </div>
        <NavBar />
        <TeamDetails />
        <MenuMobile />
        <NavbarMobile/>
    </div>
</template>

<style>
.loading{
    visibility: hidden;
    //z-index: 100;
    left: 0;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgb(2,0,36);
}
</style>
