import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueGtag from 'vue-gtag'

import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue } from 'bootstrap-vue'
import Vue2TouchEvents from 'vue2-touch-events'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/animations.css'
import '@/assets/css/fonts.css'
import '@/assets/css/global.css'

Vue.use(VueAxios, axios)
Vue.config.productionTip = false;
Vue.use(BootstrapVue)
Vue.use(Vue2TouchEvents)

// Vue.use(VueGtag, {
//   config: {
//     id: 'G-LLFBN930MY',
//     params: {
//       send_page_view: false
//     }
//   }
// }, router)

Vue.mixin({
  methods: {

  }
})

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
