<script>
import ScrollToPlugin from "gsap/ScrollToPlugin";
import {gsap} from "gsap";
export default {
    computed:{
    },
    methods: {
        scrollTo(to) {
            if(to === 1){
                    const yOffset = window.scrollY;
                    const targetOffset = document.getElementById('slide-1').getBoundingClientRect().top + yOffset - 65;

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
            }
            if(to === 2){
                    const yOffset = window.scrollY;
                    const targetOffset = document.getElementById('slide-2').getBoundingClientRect().top + yOffset - 65;

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
            }
            if(to === 3){
                    const yOffset = window.scrollY;
                    const targetOffset = document.getElementById('slide-3').getBoundingClientRect().top + yOffset - 65;

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
            }
            if(to === 4){
                    const yOffset = window.scrollY;
                    const targetOffset = document.getElementById('slide-4').getBoundingClientRect().top + yOffset - 65;

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
            }
            if(to === 5){
                    const yOffset = window.scrollY;
                    const targetOffset = document.getElementById('slide-5').getBoundingClientRect().top + yOffset - 65;

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
            }
            this.$store.commit('toggleHamburger');
            gsap.timeline().to(".team__details", {
                autoAlpha: 0,
                duration: 0.2,
            },);

        },
    },
    mounted() {
        gsap.registerPlugin(ScrollToPlugin);
    }
}
</script>

<template>
  <div class="menu__mobile">
      <div class="menu__mobile-wrapper">
          <p @click="scrollTo(1)">Rólunk</p>
          <p @click="scrollTo(2)">Szolgáltatásaink</p>
          <p @click="scrollTo(3)">Referenciánk</p>
          <p @click="scrollTo(4)">Munkatársaink</p>
          <p @click="scrollTo(5)">Kapcsolat</p>
      </div>

  </div>
</template>

<style scoped>
@media (min-width: 0px) and (max-width: 1023px){
    .menu__mobile{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #0E2044;
        visibility: hidden;
    }

    .menu__mobile p{
        color: white;
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 40px;
    }

    .menu__mobile-wrapper{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }

}

@media (min-width: 1024px){
    .menu__mobile{
        display: none;
    }
}
</style>