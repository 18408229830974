<script>
import ScrollToPlugin from "gsap/ScrollToPlugin";
import {gsap} from "gsap";
export default {
    computed:{
        activeMenu() {
            return this.$store.getters.activeMenu;
        }
    },
    methods: {


        scrollTo(to) {
            if(to === 1){

                if(this.activeMenu < to){
                    const yOffset = window.scrollY;
                    const targetOffsetPercentage = 0.5;
                    const targetOffset = document.getElementById('slide-1').getBoundingClientRect().bottom + yOffset - (window.innerHeight * targetOffsetPercentage);

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                } else{
                    const yOffset = window.scrollY;
                    const targetOffsetPercentage = 0.3;
                    const targetOffset = document.getElementById('slide-1').getBoundingClientRect().top + yOffset - (window.innerHeight * targetOffsetPercentage);

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                }
            }
            if(to === 2){
                if(this.activeMenu < to){
                    const yOffset = window.scrollY;
                    const targetOffsetPercentage = 0.2;
                    const targetOffset = document.getElementById('slide-2').getBoundingClientRect().bottom + yOffset - (window.innerHeight * targetOffsetPercentage);

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                } else{
                    const yOffset = window.scrollY;
                    const targetOffsetPercentage = 1;
                    const targetOffset = document.getElementById('slide-2').getBoundingClientRect().top + yOffset - (window.innerHeight * targetOffsetPercentage);

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                }

            }
            if(to === 3){
                if(this.activeMenu < to){
                    const yOffset = window.scrollY;
                    const targetOffsetPercentage = 0.3;
                    const targetOffset = document.getElementById('slide-3').getBoundingClientRect().bottom + yOffset - (window.innerHeight * targetOffsetPercentage);

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                } else{
                    const yOffset = window.scrollY;
                    const targetOffsetPercentage = 1;
                    const targetOffset = document.getElementById('slide-3').getBoundingClientRect().top + yOffset - (window.innerHeight * targetOffsetPercentage);

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                }
            }
            if(to === 4){
                if(this.activeMenu < to){
                    const yOffset = window.scrollY;
                    const targetOffsetPercentage = 0.1;
                    const targetOffset = document.getElementById('slide-4').getBoundingClientRect().bottom + yOffset - (window.innerHeight * targetOffsetPercentage);

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                } else{
                    const yOffset = window.scrollY;
                    const targetOffsetPercentage = 1;
                    const targetOffset = document.getElementById('slide-4').getBoundingClientRect().top + yOffset - (window.innerHeight * targetOffsetPercentage);

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                }
            }
            if(to === 5){
                if(this.activeMenu < to){
                    const yOffset = window.scrollY;
                    const targetOffsetPercentage = 0.5;
                    const targetOffset = document.getElementById('slide-5').getBoundingClientRect().bottom + yOffset - (window.innerHeight * targetOffsetPercentage);

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                } else{
                    const yOffset = window.scrollY;
                    const targetOffset = document.getElementById('slide-5').getBoundingClientRect().top + yOffset - 400;

                    gsap.to(window, {
                        scrollTo: { y: targetOffset, autoKill: false },
                        duration: 1, // Adjust the duration as needed
                        ease: "power2.inOut",
                    });
                }
            }

        },
    },
    mounted() {
        gsap.registerPlugin(ScrollToPlugin);
    }
}
</script>

<template>
  <div class="menu">
    <img src="../assets/img/logo.png" />
          <div class="navbar__items-wrapper">
              <p :class="{active: activeMenu === 1}" @click="scrollTo(1)">Rólunk</p>
              <p :class="{active: activeMenu === 2}" @click="scrollTo(2)">Szolgáltatásaink</p>
              <p :class="{active: activeMenu === 3}" @click="scrollTo(3)">Referenciánk</p>
              <p :class="{active: activeMenu === 4}" @click="scrollTo(4)">Munkatársaink</p>
              <p :class="{active: activeMenu === 5}" @click="scrollTo(5)">Kapcsolat</p>
          </div>

  </div>
</template>

<style scoped>
@media (min-width: 0px) and (max-width: 1023px){
  .menu{
      display: none;
  }
}

@media (min-width: 1024px){
    .menu img{
        width: 15vw;
        height: fit-content;
        margin-right: auto;
    }
    .menu{
        position: fixed;
        align-items: center;
        width: 100vw;
        height: 10vh;
        left: 0;
        top: 0;
        background-color: #0E2044;
        padding: 10px 5%;
        z-index: 100;
        display: flex;
    visibility: hidden;
    transform: translateY(-100px);
    }

    .navbar__items{
        justify-content: flex-end;
        background-color: red;
        position: relative;
        width: 35vw;
        height: 10vh;
        right: 5%;
        top: 0;
    }

    .navbar__items-wrapper{
        justify-content: flex-end;
        margin: 0;
        display: flex;
        padding: 0;
        width: 40vw;
        position: relative;
    //background-color: green;
        align-items: center;
    }

    .navbar__items-wrapper p{
        color: #68D0FD;
        font-family: jura, serif;
        font-size: 14pt;
        margin-right: 5%;
        cursor: pointer;
        transition: ease all 0.3s;
        margin-bottom: 0;
    }

    .active{
        color: #FEC400 !important;
    }

    .navbar__items-wrapper p:hover{
        color: #FEC400;
    }
}






</style>